<template>
  <header id="header" class="py-3">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-auto me-auto">
          <a @click="goEvents">
            <img src="../assets/images/logo-3.svg" alt="logo">
          </a>
        </div>
        <div class="col-auto">
          <a @click="doAccount">
            <img src="../assets/images/user-2.svg" alt="icon">
          </a>
        </div>
      </div>
    </div>
  </header>
  <div class="main-height">
    <section class="change-password">
      <div class="container">
        <div class="inner-container">
          <div class="row card bg-light border-0 rounded-4 p-sm-5 p-4 pt-6">
            <div class="col-12">
              <div class="fw-bold">
                <h3 class="fw-bold mb-4"> Change Password </h3>
              </div>
              <div>
                <div class="input-group border-bottom align-items-center">
                  <img src="../assets/images/lock.svg" alt="icon" height="20" class="mx-2">
                  <input type="password" name="password" autocomplete="off" class="form-control py-3" placeholder="New Password" v-model="newPassword">
                </div>
                <div class="input-group border-bottom align-items-center">
                  <img src="../assets/images/lock.svg" alt="icon" height="20" class="mx-2">
                  <input type="password" name="password" autocomplete="off" class="form-control py-3" placeholder="Retype New Password" v-model="confirmPassword" @keyup.enter="doSubmit">
                </div>
                <div v-if="error.length" class="alert-danger mt-3 text-danger">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-circle me-1 mb-1" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                  </svg>
                  {{ error }}
                </div>
                <div class="changepass-checkbox mt-2">
                  <div class="d-flex">
                    <input class="form-check-input check-btn rounded-0" type="checkbox" value="" id="flexCheckDefault" v-model="checked" />
                    <label class="form-check-label ms-1" for="flexCheckDefault">Logout from all devices.</label>
                  </div>
                </div>
                <button class="btn btn-primary w-100 mt-3 rounded-0" @click="doSubmit" id="btn">
                  <span v-show="!loading">Save</span>
                  <span v-show="loading" class="spinner-grow spinner-grow-sm" style="width: 0.8rem; height: 0.8rem;" role="status" aria-hidden="true"></span>
                  <span v-show="loading" class="spinner-grow spinner-grow-sm" style="width: 0.8rem; height: 0.8rem;" role="status" aria-hidden="true"></span>
                  <span v-show="loading" class="spinner-grow spinner-grow-sm" style="width: 0.8rem; height: 0.8rem;" role="status" aria-hidden="true"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <Footer></Footer>
</template>

<script>
import {defineComponent} from 'vue'
import UsersService from "@/services/users-service";
import Footer from "@/components/Footer.vue";
import {PasswordRequest} from "@/model/models";

export default defineComponent({
  name: "Change Password",
  components : {
    Footer
  },  
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
      checked: '',
      error: '',
      loading: false
    }
  },
  methods: {
    
    doAccount() {
      this.$router.push('/account')
    },
    goEvents () {
      this.$router.push('/events')
    },
    doCancel: function () {
      this.$router.push('/account')
    },
    doSubmit: function () {
      if (this.loading) return;
      this.loading = true;
      if (this.newPassword == '' || this.confirmPassword == '') {
        this.error = "All fields are required.";
        this.loading = false;
      } else if (this.newPassword.length < 6) {
        this.error = 'Password must have at least 6 characters.';
        this.loading = false;
      } else if (this.newPassword != this.confirmPassword) {
        this.error = "New Password fields do not match. Please re-enter.";
        this.loading = false;
      } else {
        UsersService.changePasswordRequest(new PasswordRequest("oldPassword", this.newPassword)).then((valid) => {
          if (valid) {
            if (this.checked == '') {
              localStorage.setItem('password', "true")
              this.$router.push('/account')
            } else {
              // log out from all devices
              UsersService.logoutFromAllDevices().then((valid) => {
                if (valid) {
                  localStorage.setItem('password', "true")
                  this.$router.push('/account')
                } else {
                  this.error = "There was an error while submitting password. Try again later.";
                  this.loading = false;
                }
              })
            }
          } else {
            this.error = "There was an error while submitting password. Try again later.";
            this.loading = false;
          }
        })
      }
    }
  }
})
</script>

<style>
@import '../assets/style.css';
.form-control{font-size: 1.25rem;}
#btn{background: linear-gradient(90deg, rgba(30,208,214,1) 0%, rgba(0,80,166,1) 100%); min-height: 74px;}
#pop-btn{background: linear-gradient(90deg, rgba(30,208,214,1) 0%, rgba(0,80,166,1) 100%); min-height: 48px;}
.form-control{font: 1.3rem;}
</style>
